import React from "react"
import { graphql } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { palette } from "../styles/variables.js"
import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import { PageContainer, Title, Line, SubTitle, SolidBackground, TextureBackground, Container, 
        EntryColumn, Entries, Entry, EntryThumbnail, EntryNumber, EntryTitle
        } from '../components/general/general'
import { deviceMax } from "../styles/media"

import banner from '../images/index/banner.jpg';

import "@fontsource/bebas-neue"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/400.css"
import "@fontsource/alegreya-sans/500.css"
import "@fontsource/alegreya-sans/400.css"
import "@fontsource/alegreya-sans/300.css"

const Background = styled.div`
    /* position: relative; */
    z-index: 4;
    width: 100%;
    height: 100vh;
    /* height: 30rem; */
    background-image: url(${banner});
    background-size: cover;
    /* margin-bottom: -18rem; */
 `;
const Overlay = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background-color: rgba(20, 15, 41, 0.8);
`;
const Illustrations = styled.div`
    z-index: 2;
    margin-bottom: -5rem;
    width: 100%;
    /* height: 10rem; */
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
`;
const Illustration = styled(props => <GatsbyImage {...props} />)`
    width: 19.5rem;
    height: 20rem;
    cursor: pointer;
    transition: .3s ease-in-out;
    &:hover {
        transition: .15s ease-in-out;
        transform: scale(1.05);
        filter: drop-shadow(0 0px 10px rgba(255,255,255,0.3));
    }
`;
// const SlashWrap = styled.div`

//     width: 100%;
// `;
const ShadowWrap1 = styled.div`
    z-index: 3;
    filter: drop-shadow(0 0px 10px rgba(0,0,0,0.2));
`;
const Slash1 = styled.div`
    width: 100%;
    height: 5rem;
    background: #1B2029;
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
`;
const ShadowWrap2 = styled.div`
    z-index: 2;
    filter: drop-shadow(0 0px 20px rgba(0,0,0,0.8));
`;
const Slash2 = styled.div`
    width: 100%;
    height: 5rem;
    margin-top: -5rem;
    background: #151921;
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
    filter: drop-shadow(0px 30px 15px rgba(0,0,0,0.2));
`;
const Ribbon = styled.div`
    position: relative;
    z-index: 3;
    width: 100%;
    /* height: 100%; */
    background-color: #1B2029;
`;
const NameWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${deviceMax.mobileL} { 
        padding: 2rem;
    }
`;
// const Title = styled.h1`
//     font-family: "Bebas Neue";
//     font-size: 7rem;
//     color: ${props => props.color};
//     margin: 1rem 0 0;
//     letter-spacing: .2rem;
// `;
// const Line = styled.div`
//     width: 65%;
//     opacity: .8;
// `;
const SubTitleAlt = styled(SubTitle)`
    /* margin-top: 1rem;
    font-family: "Raleway"; */
    font-size: 1.5rem;
    /* font-weight: 400;
    color: ${palette.text90}; */
    text-transform: none;
    /* text-align: center; */
    /* max-width: 1072px; */
    /* white-space: break-spaces; */
    /* margin: 0;
    letter-spacing: .2rem; */
    @media ${deviceMax.tablet} { 
        font-size: 1.2rem;
    }
    @media ${deviceMax.mobileL} { 
        font-size: 1rem;
    }
`;
const ShadowWrap3 = styled.div`
    z-index: 3;
    filter: drop-shadow(0 0px 10px rgba(0,0,0,0.2));
`;
const Slash3 = styled.div`
    width: 100%;
    height: 5rem;
    background: #1B2029;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
`;
const ShadowWrap4 = styled.div`
    z-index: 2;
    filter: drop-shadow(0 0px 20px rgba(0,0,0,0.8));
`;
const Slash4 = styled.div`
    width: 100%;
    height: 5rem;
    margin-top: -5rem;
    background: #151921;
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
    filter: drop-shadow(0px 30px 15px rgba(0,0,0,0.2));
`;
const SolidBackgroundAlt = styled(SolidBackground)`
  &&& {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background: #0E001C;
    margin-top: -5rem;
  }
`;
const ContainerAlt = styled(Container)`
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    /* border: 2px solid red; */
    @media ${deviceMax.laptopL} { 
        width: auto;  
    }
    @media ${deviceMax.laptop} {
        width: 100%; 
        padding: 0 1rem 0 1rem;
        flex-direction: column-reverse;
    }
`;
const Bios = styled.div`
    width: 100%;
    margin-right: 1rem;
    z-index: 2;
    /* border: 2px solid green; */
`;
const Bio = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 4rem;
    z-index: 3;
    @media ${deviceMax.mobileL} {
        flex-direction: column;
    }
    
`;
const Headshot = styled(props => <GatsbyImage {...props} />)`
    width: 30%;
    height: 18rem;
    border-radius: 5px;
    z-index: -1;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.tablet} {
        height: 14rem;
    }
    @media ${deviceMax.mobileL} {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        z-index: 1;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }

`;
const Copy = styled.div`
    width: 70%;
    margin-left: 1rem;
    @media ${deviceMax.mobileL} {
        width: 100%;
        margin: 0;
        /* z-index: 1;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1)); */
    }
`;
const Position = styled.div`
    margin-bottom: 1rem;
    background: #1F2239;
    border-radius: 5px;
    padding:  1rem 1.5rem;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.mobileL} {        
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
const Name = styled.h3`
    margin: 0 0 .2rem;
    font-family: "Alegreya Sans";
`;
const Role = styled.h4`
    margin: 0 0 .2rem;
    font-family: "Alegreya Sans";
    font-weight: 500;
    font-style: italic;
`;
const Organization = styled.h5`
    margin: 0;
    font-family: "Alegreya Sans";
    font-weight: 400;
`;

const Info = styled.div`
    font-family: "Alegreya Sans";
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: justify;
    text-justify: inter-word;
    color: ${palette.text90};
    background: #1F2239;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    /* padding-top: 2rem; */
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.mobileL} { 
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
const Anchor = styled.a`
    display: block;
    position: relative;
    top: -8rem;
    visibility: hidden;
`;
const EntryColumnAlt = styled(EntryColumn)`
    justify-content: flex-start;
    align-items: flex-start;
    @media ${deviceMax.laptop} { 
        width: 100%;
        background: ${props => props.background};
        border-radius: 5px;
        margin: 0 0 2rem;
        overflow-x: scroll;
        &:after {
            content: '';
            position: absolute;
            right: 1rem;
            width: 2rem;
            height: 12.8rem;
            /* padding-right: .7rem; */
            background: ${props => props.fade};
            border-radius: 0 5px 5px 0;
            z-index: 3;
        }
    }
`;
const EntryLink = styled(props => <AnchorLink {...props} />)`
    text-decoration: none;
`;
const EntriesAlt = styled(Entries)`
    margin: 0;
    @media ${deviceMax.laptop} { 
        display: flex;
        flex-direction: row;
        width: auto;
        /* overflow-x: scroll; */
        /* overflow-y: hidden; */
        margin: 0;
        background: none;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
const EntryAlt = styled(Entry)`
    @media ${deviceMax.laptop} { 
            width: 15rem;
            margin: 0 1rem 0 0;        
    }
`;
// const Thing = styled.div`
//     z-index: 10;
// `;

const TherapistsPage = (props) => {
    return (
        <Layout>
            <Head title="The Therapists" />
            <PageContainer>
                {/* <ContentWrapper> */}
                    <Background>
                        <Overlay>
                            <Illustrations>
                                {props.data.contentfulTherapists.theTherapists.map((therapist) => {
                                    return (
                                        <Illustration onClick={() => scrollTo(`#${therapist.slug}`)}
                                            image={therapist.illustration.gatsbyImageData}
                                            alt={therapist.illustration.description}
                                        />
                                    )
                                })}
                            </Illustrations>
                            {/* <SlashWrap> */}
                                <ShadowWrap1><Slash1 /></ShadowWrap1>
                                <ShadowWrap2><Slash2 /></ShadowWrap2>
                            {/* </SlashWrap> */}
                            <Ribbon>
                                <NameWrapper>
                                    <Title>{props.data.contentfulTherapists.title}</Title>
                                    <Line>
                                        <StaticImage
                                            src="../images/lines/medium-line.png"
                                            alt="Medium Weight Line"
                                            layout="constrained"
                                        />
                                    </Line>
                                    <SubTitleAlt>{props.data.contentfulTherapists.subtitle}</SubTitleAlt>
                                </NameWrapper>
                            </Ribbon>
                        </Overlay>
                    </Background>
                    
                    {/* <Thing> */}
                        <ShadowWrap3><Slash3 /></ShadowWrap3>
                        <ShadowWrap4><Slash4 /></ShadowWrap4>
                    {/* </Thing> */}

                    <SolidBackgroundAlt>
                        <TextureBackground>
                            <ContainerAlt>
                                <Bios>
                                    {props.data.contentfulTherapists.theTherapists.map((therapist) => {
                                        return (
                                            <Bio>
                                                <Anchor id={therapist.slug}/>
                                                <Headshot 
                                                    image={therapist.headshot.gatsbyImageData}
                                                    alt={therapist.headshot.gatsbyImageData}
                                                />
                                                <Copy>
                                                    <Position>
                                                        <Name>{therapist.fullName}</Name>
                                                        <Role>{therapist.role}</Role>
                                                        <Organization>{therapist.organization}</Organization>
                                                    </Position>
                                                    <Info>
                                                        {therapist.bio.bio}
                                                    </Info>
                                                </Copy>
                                            </Bio>
                                        )
                                    })}
                                </Bios>
                                <EntryColumnAlt background='#1F2239' fade='linear-gradient( 90deg, rgba(0,0,0,0), #1F2239)'>
                                    <EntriesAlt background='#1F2239'>
                                        {props.data.contentfulDiscussions.discussions.map((discuss) => {
                                            return (
                                                <EntryLink to={`/discussions#${discuss.discussionId}`}>
                                                    <EntryAlt>
                                                        <EntryThumbnail
                                                            image={discuss.discussionThumbnail.gatsbyImageData}
                                                            alt={discuss.discussionThumbnail.description}
                                                        />
                                                        <EntryNumber>{discuss.discussionNumberText}</EntryNumber>
                                                        <EntryTitle>{discuss.discussionTitle}</EntryTitle>
                                                    </EntryAlt>
                                                </EntryLink>
                                            )
                                        })}
                                    </EntriesAlt>
                                </EntryColumnAlt>
                            </ContainerAlt>
                        </TextureBackground>
                    </SolidBackgroundAlt>
                    
                {/* </ContentWrapper> */}
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulTherapists {
            title
            subtitle
            theTherapists {
                slug
                name
                fullName
                role
                organization
                bio {
                    bio
                }
                headshot {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                illustration {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
            }
        }
        contentfulDiscussions {
            discussions {
                discussionId
                discussionNumberText
                discussionTitle
                discussionThumbnail {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
            }
        }
    }
`

export default TherapistsPage